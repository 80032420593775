// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
  },
};

class Blog_2016_10_15_Aca_Leaderguide_Rendezvous_Beau_Beau extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AII" title="Beau Beau" />
        <p>
          After <GlossaryLink id="ascend">ascending</GlossaryLink> and{' '}
          <GlossaryLink id="haul-system">hauling</GlossaryLink> in Egypt 1 I
          went with a group to Beau Beau aka Egypt 1.5.
        </p>
        <p>
          We started the canyon with some instruction on{' '}
          <GlossaryLink>down climbing</GlossaryLink>. The instruction was more
          what we would say to clients and show them how many things are done.
        </p>
        <Image caption="Practicing partner captures" image={data._1} />
        <Image
          caption="We practiced down climbs as well as providing hip belays for the descent down this little crack"
          image={data._2}
        />
        <Image
          caption="A few people were able to do the entire canyon without tying into their harness"
          image={data._4}
        />
        <Image
          caption="Lots of down climbs in Beau Beau. Sequencing was key for a group this large"
          image={data._5}
        />
        <Image image={data._6} />
        <Image image={data._7} />
        <Image image={data._8} />
        <Image
          caption="We found a snake on the hike out. I know nothing about snakes, but apparently this one is fully grown and was quite skinny."
          image={data._9}
        />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter(
  '2016-10-15-aca-leaderguide-rendezvous-beau-beau'
)(Blog_2016_10_15_Aca_Leaderguide_Rendezvous_Beau_Beau);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau/9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
